<template>
    <div className="terms-and-conditions">
      <h5 class="modal-title"><b>PEUGEOT Malaysia Weekender Experience Campaign 2024</b></h5>
      <span><b>Terms and Conditions</b></span>
      <p><b>Campaign Period: 3rd December 2024 - 2nd December 2025</b></p>

          <h6>1. Campaign Overview:</h6>
          <p>
            The "PEUGEOT Malaysia Weekender Experience Campaign 2024" (hereinafter referred to as the "Campaign") is
            organized by PEUGEOT Malaysia (hereinafter referred to as the "Organizer").
          </p>
          <p>To participate in the Campaign, participants must:</p>
          <ol>
            <li style="margin-bottom: 1rem">Join the Peugeot Weekender Experience and take any Peugeot vehicle for a test drive during the campaign period.</li>
            <li style="margin-bottom: 1rem">Answer the following question in their social media post caption:
              <blockquote><b>
                “Which Peugeot model would you choose for the ultimate weekend experience, and why?”
              </b></blockquote>
            </li>
            <li style="margin-bottom: 1rem">Take a photograph featuring the Peugeot vehicle being test-driven.</li>
            <li style="margin-bottom: 1rem">
              Share the photograph on their social media accounts with their caption answering the question, including
              the hashtags #PeugeotWeekenderExperience and #PeugeotMalaysia.
            </li>
            <li>Tag @peugeotmalaysia in the post.</li>
          </ol>

          <p>Each month, one (1) winner with the most creative answer will be selected. They will receive a limited-edition diecast model of the PEUGEOT 408.</p>

          <h6>2. Eligibility:</h6>
            <p>The campaign is open to all Malaysian residents with a valid full driver’s license.</p>
            <p>
              Employees of PEUGEOT Malaysia, Stellantis Malaysia, its affiliates, agencies, and their immediate family
              members (spouse, parents, children, and siblings) and household members are not eligible to participate in
              the Campaign.
            </p>

          <h6>3. Campaign Period:</h6>
          <p>
            The Campaign begins at 00:00:00 on 3rd December 2024 and concludes at 23:59:59 on 2nd December
            2025. Entries received outside this period will not be considered.
          </p>

          <h6>4. Campaign Mechanics:</h6>
          <p>To participate, customers must book a test drive for any vehicle between December 3rd, 2024, and December 2nd, 2025, through the Weekender Experience microsite, which will direct them to an authorized dealer outlet.</p>
          <p>Once at the dealership for the test drive, PEUGEOT Sales Advisors (SAs) will provide participants with a form for contest entry. Participants are required to fill in the form where they must submit the following information:</p>
            <ul>
              <li>Full Name</li>
              <li>Contact Number</li>
              <li>Agreement to T&C</li>
            </ul>

          <h6>5. Booking and Payment:</h6>
          <p>
            Customers interested in participating in the Campaign must visit the microsite and book a test drive. No
            payment is required to book a test drive.
          </p>

          <h6>6. Vehicle Procurement:</h6>
          <p>
            The test drive of the vehicles is subject to availability and the dealership's standard delivery timeline.
            Customers are required to complete all necessary documentation as per the dealership's requirements before
            taking delivery of the vehicle.
          </p>

          <h6>7. Winner Selection:</h6>
          <p>
            Only entries that are complete and comply with the Terms and Conditions of the Contest will be accepted.
            Winners will be announced monthly on Peugeot’s social media pages.
          </p>
          <p>One (1) winner will be selected each month based on the creativity and relevance of their answer to the following question:</p>
          <p><b>“Which Peugeot model would you choose for the ultimate weekend experience, and why?”</b></p>
          <p>To be eligible, participants must:</p>
            <ol>
              <li>Join the Peugeot Weekender Experience and take any Peugeot car for a test drive during the campaign period.</li>
              <li>Take a photo with the Peugeot car they are test driving.</li>
              <li>Share the photo on their social media with a caption answering the question and describing their Weekender Experience, ensuring the hashtags #PeugeotWeekenderExperience and #PeugeotMalaysia are included.</li>
              <li>Tag @peugeotmalaysia in their post.</li>
            </ol>

          <h6>8. Winner Announcement:</h6>
          <p>Winners will be notified using the provided contact information and must respond within 72 hours to claim
            their prize. Failure to do so will result in disqualification, and an alternate winner will be selected. The
            decision is final.</p>

          <h6>9. Prize Details:</h6>
          <p>The monthly prize will consist of a limited-edition diecast model of the PEUGEOT 408.</p>
          <p>In the event that the selected prize is unavailable, the Organizer reserves the right to substitute it with an alternative prize of equal or greater value. The Organizer also retains the right to modify, change, or replace the prize at any time without prior notice. All decisions made by the Organizer in relation to the prize are final and binding.</p>

          <h6>10. Prize Redemption Period</h6>
          <p>If Winners are required to physically redeem their prizes, then the Winners must redeem their prizes at the location and within the period specified by the Organizer ('Prize Redemption Period').</p>
          <p>If Winners fail to claim their prizes (whether claiming the prize personally or through a representative nominated by the Winner) within the designated Prize Redemption Period, the Organizer reserves the right to withdraw the prize or award it to another participant/Winner.</p>

          <h6>11. Identity Verification</h6>
          <p>Winners must present their proof of identity for verification purposes. Winners who require a representative to claim the prize on their behalf must ensure that the representative submits a letter of authorization signed by the Winner, and this authorization letter must be attached along with copies of the Winner's proof of identity and the representative's proof of identity.</p>

          <h6>12. Prize Fulfilment</h6>
          <p>The Organizer reserves the right to determine the method of delivery or redemption of prizes, and the Organizer will not be liable for any difficulties, damages, or loss of prizes that occur during the delivery or redemption process.</p>

          <h6>13. Rights To Contest Entry Information and Verification Evidence</h6>
          <p>All entry information and any proof of purchase submitted by Participants in this Contest shall become the property of the Organizer. Where applicable, the copyrights and all intellectual property rights (including moral rights) existing in all Entries, proof of purchase, and other materials submitted or provided by Participants to the Organizer, including but not limited to digital or non-digital materials, shall vest in the Organizer.</p>
          <p>Where another individual participates in the Contest on behalf of the Participant, the Participant hereby acknowledges that consent from that individual has been obtained, and all information provided is true.</p>

          <h6>14. Consent to Process Participant's and Partner's Details</h6>
          <p>By participating in this Contest, Participants/Winners agree and consent to the Organizer's use of the Participant's/Winner's name, address, image, likeness, audio and/or video recordings, personal details, documents, and other information for advertising, marketing, publicity, prize fulfilment and/or any other purposes, without prior notice and without providing any royalty or compensation to the Participant/winner.</p>
          <p>The Participant/Winner hereby grants permission to the Organizer to the extent necessary under the law for the publication and use of the Participant's/Winner's name, address, image, likeness, audio and/or video recordings, personal details, documents, and other information. Participants/Winners shall not be entitled to claim ownership or any other form of compensation for such information.</p>

          <h6>15. Intellectual Property</h6>
          <p>The Organizer reserves the sole and absolute discretion to use and exploit intellectual property by any means or medium and in any manner and at any time deemed appropriate without first obtaining any permission or making any payments to the Participants and/or winners of this Contest and/or representatives.</p>

          <h6>16. Disqualification of Participant/Winner</h6>
          <p>The Organizer reserves the right to disqualify, at its sole discretion, any Participant/Winner who interferes with the mechanism, system, software of this Contest, or any other process related to this Contest.</p>
          <p>The Organizer reserves the right to disqualify, at its sole discretion, any Participant and/or Entry found (at the Organizer's discretion) to be disrespectful or unsuitable for public view, or found or suspected to be cheating/hacking/disrupting the Contest submission process, operation of this Contest, or violating any of the Terms and Conditions of this Contest. The Organizer reserves the right to take legal action against any individual believed to be involved in fraudulent activities or any other activities detrimental to this Contest, the Organizer, or the Contest submission process.</p>

          <h6>17. General Terms:</h6>
          <p>The Organizer and all other parties involved in this Contest, including but not limited to directors, officers, and agents, shall not be liable in any way for the following:</p>
            <ol type="a">
              <li style="margin-bottom: 1rem">Interference or disruption by unauthorized parties during this Contest; and/or</li>
              <li style="margin-bottom: 1rem">Any form of technical disruptions and/or human errors in the management of the Contest and processing of Entries.</li>
              <li style="margin-bottom: 1rem">The Organizer reserves the right to amend the terms and conditions at its discretion without any prior notice. All decisions by the Organizer are final and any correspondence, appeals, complaints, or inquiries regarding such decisions will not be entertained.</li>
              <li style="margin-bottom: 1rem">The Organizer, agents, sponsors, and representatives shall not be liable to fulfill any of their obligations in relation to the Contest and these Terms and Conditions and Contest regulations if they are unable to do so due to circumstances beyond their control and shall not be responsible for compensating Participants in any way in such circumstances.</li>
            </ol>  

          <h6>18. Additional Terms and Conditions</h6>
            <p>(a) By participating in this Contest, you are deemed to have read, understood, and agreed that your personal data will be processed in accordance with the Privacy Notice as stated at <a href="https://www.PEUGEOT.com.my/tools/privacy-policy.html" target="_blank" style="color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))">https://www.PEUGEOT.com.my/tools/privacy-policy.html</a>.</p>
            <p>(b) Participants guarantee, acknowledge and agree that the submitted Entry information (including any photos, drawings, images, slogans, any other materials or creative works, including audio or video recordings) shall be original and not infringe upon any third party's rights and/or violate any copyright, patent, trademark, proprietary, personal, privacy, or moral rights of any third party.</p>
            <p>(c) Participant guarantee, acknowledge and agree that they waive and disregard or have obtained a waiver or disregard of all rights (including moral rights) from all individuals who have contributed to the creation of the Entry and policy (collectively "Contributors") entitled to be claimed by the Contributors and/or Participant, now or in the future, anywhere in the world.</p>
            <p>(d) Participants guarantee, acknowledge and agree that the Entry submitted by the participant does not contain elements of nudity, pornographic images or sexual themes, graphic violence, defamatory or libelous statements or materials deemed to contravene the laws/offensive or potentially violate Malaysian laws or materials that may tarnish the image of the Organizer or cause the Organizer to be disgraced. Any violation of this clause shall give absolute discretion to the Organizer to remove and reject and/or delete any Entry or post immediately without prior notice.</p>
            <p>(e) Participants guarantee, acknowledges and agree that the Organizer has the sole right to use or exploit the participant's Entry or any part thereof in any and all forms of media through any and all methods or means worldwide for the full term of copyright including all renewals together with the right to grant others the authority to do so without the need to pay any additional compensation or royalties to the participant. For the avoidance of doubt, the Organizer has no obligation to use or exploit any part thereof.</p>
            <p>(f) Participants guarantees, acknowledges and agree that they will not use the Entry or any part thereof for purposes other than those specified in the Terms and Conditions. If the Entry or any part thereof is published or distributed or handled in any way without prior permission from the Organizer, the Organizer reserves the right, at its sole discretion, to disqualify the participant and/or revoke any prizes won and shall not be liable for any liability whatsoever towards them.</p>
            <p>(g) All rights and privileges granted herein to the Organizer cannot be revoked and are not subject to cancellation, restriction, or injunction under any and all circumstances. The Participant shall not under any circumstances be entitled to injunctive relief or to restrain or otherwise interfere with the organization of this Contest, the publication, distribution, exhibition, and/or exploitation of this Contest and/or any products based on and/or arising from this Contest.</p>
            <p>(h) The Organizer reserves the right at any time to modify, amend, delete, or add to the Terms and Conditions and other rules including the mechanisms of this Contest at its sole discretion.</p>
            <p>(i) Submission of an Entry does not guarantee that the participant will enter this Contest. The Organizer has absolute discretion to reject or refuse to accept the submission of Entries and the participation of participants for reasons including (but not limited to) if the Entry is incomplete or any provision of the Terms and Conditions is not complied with.</p>
            <p>(j) The Organizer reserves the right to disqualify and/or exclude participants and/or withdraw prizes (at any stage of the Contest) if:</p>

          <ol type="i">
            <li style="margin-bottom: 1rem">The participant is not eligible or does not meet any eligibility criteria outlined in these Terms and Conditions; or</li>
            <li style="margin-bottom: 1rem">The participant breaches these Terms and Conditions and other rules; or</li>
            <li style="margin-bottom: 1rem">The participant violates any laws or regulations in force; or</li>
            <li style="margin-bottom: 1rem">At the sole discretion of the Organizer, the Organizer believes that the Participant has attempted to undermine the conduct of this Contest through fraud, deception, or deceit.</li>
            <li style="margin-bottom: 1rem">If disqualification occurs after the prize has been awarded, the Organizer reserves the right to demand the return of the prize or payment of an equivalent value from the disqualified Participant.</li>
          </ol>
          <p>(k) While the Organizer will make every reasonable effort to conduct necessary checks regarding the eligibility of participants, failure to disqualify any ineligible participants shall not be deemed a waiver by the Organizer.</p>
          <p>(l) If applicable, cash prizes will be awarded to the winner in the form of a cheque. Cash prize winners are responsible for any and all taxes (if applicable) due to the giving or receiving of the prize and all related banking charges (including out-of-state cheque fees) imposed by the bank for clearing the cheque.</p>
          <p>(m) Participants shall not question or make any oral or written complaints, announcements, or public statements regarding this Contest either during or after the Contest Period.</p>
          <p>(n) If any term herein is invalid, illegal, or unenforceable, it shall not affect or impair the enforcement of the other terms and conditions of this Contest.</p>
          <p>(o) Unless stated otherwise, all transportation costs, internet charges, personal expenses, and/or any costs, fees, or any kind of expenditure incurred by the participant in relation to this Contest (whether for the purpose of entry, participation, or receiving any benefits or prizes from the Contest) or for the purpose of redeeming prizes or related travel are the sole responsibility of the Participant/Winner, and the Organizer, its affiliates, authorized agents, or agencies shall not bear any responsibility for these costs/charges/fees/expenditures.</p>
          <p>(p) The Organizer, its affiliates, authorized third parties, and associated agencies shall not be liable for any lost, damaged, delayed entries due to postal services, or caused by computer, IT, or any technological errors. Participants are responsible for ensuring their entries comply with all Contest rules and Terms and Conditions, and that their entries are received by the Organizer in the form required by the Organizer.</p>
          <p>(q) By participating in this Contest, all participants agree to release and indemnify the Organizer, its affiliates and agencies, and their employees, officers, directors, and representatives from any claims, losses, or damages arising out of or in connection with their participation in this Contest (including but not limited to, any errors in calculating any transactions, any damage or malfunction of any computer system or equipment), any activities related to this Contest, and the acceptance and/or use, misuse, or possession of any prizes awarded herein. All costs incurred by the participant in connection with and/or related to the Contest and prizes, including but not limited to, postage or Internet Service Provider (ISP) charges (if applicable), all transportation costs, accommodation communication charges, food costs, and other related expenses incurred by the Participant due to and/or in connection with their participation in the Contest, collection costs, and additional costs related to the prizes such as insurance costs, etc. shall be borne solely by the Participant. The Organizer has no obligation to reimburse the Participant for any costs and expenses incurred by them.</p>
          <p>(r) Participants acknowledge that their participation in this Contest is at their own risk.</p>
          <p>(s) The Organizer, agents, sponsors, representatives, affiliates, directors, officers, and employees, agents, and assignees, shall not be liable to any Participant for:</p>
          <ol type="i">
            <li style="margin-bottom: 1rem">Failure to win any prize in the Contest, defective prizes caused by negligence, actions and/or omissions, or misuse of the prize or any loss, damage, expenses, claims, liabilities, injuries, deaths, accidents suffered by the Participant during the Contest or arising out of or in connection with the Contest, the Participant's participation in the Contest, and/or the prizes awarded.</li>
            <li style="margin-bottom: 1rem">Any delay and/or failure to receive and submit Contest Entries due to any network, communication, ISP, or system damage, disruption, and/or failure experienced by the Organizer's telecommunication service provider or Participant’s, and/or caused by the participation or downloading steps of any material in the Contest.</li>
            <li style="margin-bottom: 1rem">Any errors (including errors in notifying Contest Winners), omissions, disruptions, interruptions, outages, defects, delays in processing or delivery, communication line failures, theft, destruction, alteration, or unauthorized access to entries, or late or lost entries whether arising during processing or delivery due to server functions, viruses, malware, or other causes beyond the control of the Organizer.</li>
          </ol>

          <h6>19. Force Majeure</h6>
          <p>The Organizer, agents, sponsors, and representatives shall not be liable to fulfil any of their obligations in relation to the Contest and these Terms and Conditions and Contest regulations if they are unable to do so due to circumstances beyond their control and shall not be responsible for compensating Participants in any way in such circumstances.</p>

          <h6>20. Data Protection</h6>
          <p>The Organizer takes reasonable precautions to securely store participants' personal data and may require third-party data processors to do the same. Please note, however, that the Organizer may disclose participants' personal data if required to do so by law, or with a search warrant, subpoena, or court order.</p>

          <h6>21. Rights Transfer</h6>
          <p>The Organizer reserves the right to transfer any or all its rights and obligations under these Terms and Conditions to any of its affiliated companies at the sole discretion of the Organizer.</p>
          <p>Participants also agree that any authorization given in these Terms and Conditions to the Organizer shall extend to the affiliated companies of the Organizer, whereby Participants will be deemed to have also granted such authorization to the relevant affiliated companies of the Organizer.</p>
    </div>
</template>
  
<script>
export default {
  name: 'TermsAndConditions',
};
</script>

<style scoped>

</style>